import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">About Me.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">

Andrés Torres
Desarrollador Full Stack | Laravel & Vue.js Expert

Soy un desarrollador full stack con experiencia en la creación de aplicaciones web modernas, utilizando Laravel en el backend y Vue.js en el frontend, incluyendo la Vue Composition API. Mi enfoque está en construir soluciones eficientes, escalables y bien estructuradas, con un énfasis en buenas prácticas como la arquitectura de repositorios y el desarrollo orientado a pruebas (TDD).

Además, tengo experiencia en la gestión de tareas automatizadas con cron jobs y la optimización de aplicaciones para entornos de producción, asegurando un alto rendimiento y fiabilidad. Estoy siempre en constante aprendizaje, buscando mejorar mis habilidades y aportar valor a cada proyecto en el que participo.
        </p>
        <div className="about-detail-main">
          <p className="about-detail">Nombre</p>
          <p className="about-detail-info">Andres Torres</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Nationality</p>
          <p className="about-detail-info">Colombiana </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Phone</p>
          <p
            className="about-detail-info email"
            onClick="location.href='tel:(+57)3175791446'"
          >
            (+57)3175791446
          </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Email</p>
          <p
            className="about-detail-info email"
            onClick="location.href='mailto:hello@biogi.com'"
          >
            hello@biogi.com
          </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Experience</p>
          <p className="about-detail-info">6+ years</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Freelance</p>
          <p className="about-detail-info">Available</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Skype</p>
          <p className="about-detail-info">hello.biogi</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Language</p>
          <p className="about-detail-info">English</p>
        </div>
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
